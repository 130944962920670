<template>
  <div class="flex-column">
    <div style="margin-top: 5px" class="flex-row align-center justify-center">
      <el-button type="text" style="color: #000" @click="toDashboard"
        >首页</el-button
      >
      <span class="line"> | </span>
      <el-button type="text" style="color: #000" @click="showContact = true"
        >联系方式</el-button
      >
      <span class="line"> | </span>
      <el-button
        type="text"
        style="color: #000"
        @click="showBusinessLicense = true"
        >营业执照</el-button
      ><span class="line"> | </span>
      <el-button
        type="text"
        style="color: #000"
        @click="showTravelAgency = true"
        >旅游资质</el-button
      >
    </div>
    <span style="margin-top: 5px">
      © exotictravelvip.com 2024 vip异域风情
    </span>
    <div
      style="margin-top: 5px"
      class="flex-row align-center justify-center"
      @click="redirectToExternalUrl"
    >
      <img src="@/assets/dashboard/ICP.png" alt="" />
      <span>京ICP备2024042182号-1</span>
    </div>
    <Contact :ifShow="showContact" @showContact="handleContact" />
    <el-dialog
      :visible.sync="showBusinessLicense"
      @close="showBusinessLicense = false"
    >
      <img src="../../assets/liscence/agency.png" width="500" alt="" />
      <!-- <el-image
        :src="require('../../assets/liscence/business.png')"
        fit="fill"
      ></el-image> -->
    </el-dialog>
    <el-dialog
      :visible.sync="showTravelAgency"
      @close="showTravelAgency = false"
    >
      <img src="../../assets/liscence/business.png" width="500" alt="" />
      <!-- <el-image
        :src="require('../../assets/liscence/agency.png')"
        fit="fill"
      ></el-image> -->
    </el-dialog>
  </div>
</template>
<script>
import Contact from "@/components/contact/index.vue";
export default {
  data() {
    return {
      showContact: false,
      showBusinessLicense: false,
      showTravelAgency: false,
    };
  },

  methods: {
    handleContact(val) {
      this.showContact = val;
    },
    redirectToExternalUrl() {
      // 使用 window.location.href 进行外部URL跳转
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
    toDashboard() {
      this.$router.push("/");
    },
  },
  components: {
    Contact,
  },
};
</script>
