<template>
  <div class="flex-row align-center">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      active-text-color="#ff9638"
      text-color="#393939"
    >
      <el-menu-item index="index">首页</el-menu-item>
      <el-submenu index="command">
        <template slot="title">路线推荐</template>
        <el-menu-item
          :index="item.name"
          v-for="(item, index) in tabList"
          :key="index"
          @click="chooseBanner(item)"
          >{{ item.name }}</el-menu-item
        >
      </el-submenu>
      <el-menu-item index="guide">景点攻略</el-menu-item>
      <el-popover placement="bottom" title="" width="10" trigger="hover">
        <div
          class="flex-column"
          style="justify-content: center; align-items: center"
        >
          <img src="@/assets/header/qrCode.png" width="150" alt="" />
          <span style="font-size: 14px; color: #393939"
            >扫码打开vip异域风情手机版</span
          >
        </div>
        <div slot="reference" class="flex-row align-center">
          <el-menu-item index="phone">手机版</el-menu-item>
        </div>
      </el-popover>
    </el-menu>
  </div>
</template>
<script>
import { getNav } from "@/api/nav.js";
export default {
  name: "HeaderItem",
  props: ["qrCode"],
  data() {
    return {
      activeIndex: "index",
    };
  },
  computed: {
    tabList() {
      return this.$store.state.navList;
    },
  },
  mounted() {
    this.$store.dispatch("fetchNavList");
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      switch (keyPath[0]) {
        case "guide":
          this.$router.push("guide");
          break;
        case "index":
          this.$router.push("/");
          break;
        case "command":
          // this.activeIndex = "index";

          break;
      }
    },
    chooseBanner(tab) {
      this.$router.push("/").then(() => {
        this.tabList.forEach((item) => {
          item.ifActive = false;
          if (item.id == tab.id) {
            item.ifActive = true;
          }
        });
        this.$store.commit("setNavList", this.tabList);
      });
    },
  },
};
</script>
<style lang="scss">
// .el-menu--horizontal > .el-menu-item.is-active {
//   background-color: #ff9638 !important;
// }
</style>
