import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index/index.vue"),
  },
  {
    path: "/landingPage",
    name: "landingPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/landingPage/index.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/guide/index.vue"),
  },
  {
    path: "/details",
    name: "景点详情",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/index/indexDetails/index.vue"
      ),
  },
  {
    path: "/guideDetails",
    name: "攻略详情",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/guide/guideDetails/index.vue"
      ),
  },
];

const router = new VueRouter({
  onSameUrlNavigation: "reload",
  routes,
});
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
