// 在你的 store.js 文件中

import Vue from "vue";
import Vuex from "vuex";
import { getNav } from "@/api/nav.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navList: [], // 用于存储导航数据的数组
  },
  getters: {
    getNavList: (state) => state.navList,
  },
  mutations: {
    setNavList: (state, navList) => {
      state.navList = navList;
    },
  },
  actions: {
    fetchNavList: async ({ commit }) => {
      const res = await getNav();
      const navList = res.data.map((item) => ({ ...item, ifActive: false }));
      commit("setNavList", navList);
      return navList;
    },
  },
});
