import axios from "axios";
import { MessageBox, Message } from "element-ui";
// create an axios instance
const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://www.exotictravelvip.com/api"
      : "http://111.229.146.166/api",
  //   baseURL: config.baseURL,
  timeout: 10000000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // config.headers["X-Access-Token"] = getToken();
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (response.status != 200) {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      return new Error(res.message || "Error");
    } else {
      return res;
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    // if (error.response.status === 401) {
    //   window.location.href = "/";
    // }
    return Promise.reject(error);
  }
);

export default service;
