<template>
  <div class="flex-between align-center" style="width: 70%">
    <div class="header-text">VIP异域风情</div>
    <HeaderItem :qrCode="qrCode" />
    <div>
      <el-popover placement="bottom" title="" trigger="hover">
        <div
          class="flex-column"
          style="justify-content: center; align-items: center"
        >
          <div class="flex-row">
            <img
              src="@/assets/header/contact.png"
              width="300"
              height="200"
              alt=""
            />
          </div>
          <span style="font-size: 14px"
            >更多优惠信息 或有任何疑问 可扫码咨询</span
          >
        </div>
        <div slot="reference" class="flex-row align-center">
          <img
            src="@/assets/header/weChat.png"
            width="40"
            alt=""
            style="margin-right: 10px"
          />
          <span style="color: #393939">联系方式</span>
        </div>
      </el-popover>
    </div>
    <!-- <div class="flex-row">
      <el-button type="text" class="text-color">登录</el-button>
      <el-button type="text" class="text-color">注册</el-button>
    </div> -->
  </div>
</template>

<script>
import HeaderItem from "@/components/header/headerItem.vue";
import { getConfig } from "@/api/config.js";
export default {
  name: "Header",
  data() {
    return {
      qrCode: "",
    };
  },
  components: {
    HeaderItem,
  },
  mounted() {
    this.getConfigInfo();
  },
  methods: {
    getConfigInfo() {
      getConfig().then((res) => {
        sessionStorage.setItem("config", JSON.stringify(res.data[0]));
        this.qrCode = res.data[0].qrcode;
      });
    },
  },
};
</script>
<style scoped>
.header-text {
  font-size: 31px;
  color: #ff9638;
  line-height: 49px;
  font-weight: bold;
}
.text-color {
  color: #ff9638;
}
</style>
