<template>
  <div id="app">
    <el-container v-if="!isLandingPage">
      <el-header>
        <Header />
      </el-header>
      <el-main><router-view /></el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
    <div v-else>
      <el-main><router-view /></el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";
import { createCollection, updateStayTime } from "@/api/infoCollection.js";
export default {
  data() {
    return {
      referrer: "",
      id: "",
      starTime: "",
      ifVisit: "",
      isLandingPage: false,
    };
  },
  components: {
    Header,
    Footer,
  },
  watch: {
    $route: "checkRoute",
  },
  mounted() {
    this.starTime = new Date().getTime();
    this.setUsername();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.checkRoute();
  },
  methods: {
    /**
     * 获取来源
     */
    getReferrerFromRoute(uname) {
      const key = "referrer";
      const url = window.location.href;

      const match = url.match(new RegExp(`[?&]${key}=([^&]+)`));
      // 判断是否匹配到参数
      const referrerValue = match ? match[1] : null;
      // 创建一个 URL 对象
      const currentUrl = new URL(window.location.href);
      // 获取主机部分
      const host = currentUrl.hostname;
      if (referrerValue) {
        createCollection({
          resouce_url: referrerValue,
          uname: uname,
          domain_url: host,
        }).then((res) => {
          this.id = res.id;
        });
      }
    },
    checkRoute() {
      this.isLandingPage = this.$route.name == "landingPage";
      console.log(this.isLandingPage);
    },
    handleBeforeUnload() {
      if (this.id != "") {
        const endTime = new Date().getTime();
        // 计算停留时间
        const timeSpentOnPage = endTime - this.startTime;
        updateStayTime({ id: this.id, stop_time: timeSpentOnPage }).then(
          (res) => {
            console.log(res);
          }
        );
      }
    },
    generateRandomString(length) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        result += chars.charAt(randomIndex);
      }
      localStorage.setItem("visit", result);
      return result;
    },
    setUsername() {
      this.ifVisit = localStorage.getItem("visit");
      if (this.ifVisit != null) {
        this.getReferrerFromRoute(this.ifVisit);
      } else {
        const username = this.generateRandomString(16);
        this.getReferrerFromRoute(username);
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  margin: 0;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
body {
  margin: 0 !important;
}
.line {
  margin: 0 5px;
}

.flex-row {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  // width: 100vw;
}
.el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
  min-height: 100px;

  font-size: 14px;
}

.el-main {
  padding: 0px !important;
  min-height: calc(100vh - 150px);
}

.el-pager li {
  background: transparent !important;
  min-width: 30px !important;
}
.el-pager li.active {
  color: #fff !important;
  background: #ff9638 !important;
  border-radius: 50%;
  height: 30px;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: transparent;
}
.el-pagination button:disabled {
  background: transparent;
}
</style>
