<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <img :src="config.qrcode" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Contact",
  props: ["ifShow"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    ifShow(newValue, oldValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit("showContact", false);
    },
  },
  computed: {
    config() {
      return JSON.parse(sessionStorage.getItem("config"));
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 350px;
}
</style>
